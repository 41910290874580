<template>
    <section class="main">
        <h1>{{ $route.name }}</h1>
        <div class="grid-container">
            <div class="row">
                <div class="col col_25">
                    <img src="@/assets/media/pogo-20-04.jpg" />
                </div>
                <div class="col col_75">
                    <p>My story as a Pokémon GO trainer began the week it officially launched. At first I wasn't interested in the game, given it would be just SO much different from the main series games. But the incredible hype the game received during the first days made me eventually install the game and get hooked to it. When the game launched, I was pretty much the only one in my class at the vocational college not playing it - a few weeks later I was pretty much the only one in my class actually playing it and got the occasional "people still play this game?" when I told them about it.</p>
                    <p>As of April, 2020, I walked more than 6100km, caught over 86000 Pokémon, spun nearly 87000 Pokéstops, gained almost 52 million EXP (which equals reaching the maximum level - 40 - more than twice), did more than 25000 gym battles and gained dozens of beautiful, ugly, funny, sad and lots of other memories. I am not especially proud of the goals I achieved - there are so, so many other passionate trainers out there who would laugh at those numbers.</p>
                    <p>but just looking at the kilometer count I racked up (most of which were done by foot) I feel happy for all the things the game made me do, like exploring the neighborhood, visiting new places, discovering new walking paths I didn't know even existed, walking more than 22km in a single day at GO Fest 2019 (given my brother and I only had slept for two hours each it was pretty rough but an unbelievable experience) and so on.</p>
                    <p>Even though the big hype faded a long time ago due to numerous bugs, glitches, unstable servers around launch time and many other things, Pokémon GO still has  a big, passionate playerbase and the game evolved in many ways to augment our reality. If you're one of those players who joined the hype train early on but jumped off a few weeks later, be sure to give it another try.</p>
                    <p>After its launch in 2016, there are still many things to improve, but the game offers much more content compared to what had been available at the time Niantic launched the game with PvP, raids, playing with your Pokémon and whatnot.</p>
                </div>
            </div>
        </div>
    </section>
</template>